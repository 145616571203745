import { useRuntimeConfig } from '#imports'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  return {
    provide: {
      runtimeConfig: config
    }
  }
})
