import { default as _91channelUrl_93coTr4lNSlpMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/chat/[channelUrl].vue?macro=true";
import { default as forgetPwdsaScYPmHAfMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/forgetPwd.vue?macro=true";
import { default as businessCardtwAQB0JH2vMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/businessCard.vue?macro=true";
import { default as conversation20NDyP907PMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/conversation.vue?macro=true";
import { default as feedbackA0rzLnPkl9Meta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/feedback.vue?macro=true";
import { default as historyfQ5V8SchtJMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/history.vue?macro=true";
import { default as homeqZnUCvdC5xMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/home.vue?macro=true";
import { default as profileST8hSeqJZmMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/profile.vue?macro=true";
import { default as assignYt7b8boVuvMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/assign.vue?macro=true";
import { default as basic1ogyZJlcUoMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/basic.vue?macro=true";
import { default as blacklistOUMTkl7sUfMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/blacklist.vue?macro=true";
import { default as conversationTagPVum5eyrswMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/conversationTag.vue?macro=true";
import { default as queueY5sT0wYH0JMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/queue.vue?macro=true";
import { default as quickReply88Pos4JvBaMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/quickReply.vue?macro=true";
import { default as rolesyudp4mAw9JMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/roles.vue?macro=true";
import { default as satisfactionVcex2dFec7Meta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/satisfaction.vue?macro=true";
import { default as sensitivemFyzmiUjNHMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/sensitive.vue?macro=true";
import { default as teamyllA8WfsJHMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/team.vue?macro=true";
import { default as setupxqaZf401VPMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/webAccess/setup.vue?macro=true";
import { default as webZiBPBDgzgSMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/webAccess/web.vue?macro=true";
import { default as webAccesstaeklfDA3AMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/webAccess.vue?macro=true";
import { default as setupAbPi67W5zYMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup.vue?macro=true";
import { default as indexb4NojfA6IZMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index.vue?macro=true";
import { default as inviteUserVerifyitJ4Iw1CqCMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/inviteUserVerify.vue?macro=true";
import { default as loginNylpizp0EMMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/login.vue?macro=true";
import { default as registerJwHMV599erMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/register.vue?macro=true";
import { default as registerVerifyBC5CVh3WTHMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/registerVerify.vue?macro=true";
import { default as resetPwd0agTdlHzHQMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/resetPwd.vue?macro=true";
import { default as googleMail9cekhKBqA2Meta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/test/googleMail.vue?macro=true";
import { default as verifyFxp1VgTEdqMeta } from "C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/verify.vue?macro=true";
export default [
  {
    name: _91channelUrl_93coTr4lNSlpMeta?.name ?? "chat-channelUrl",
    path: _91channelUrl_93coTr4lNSlpMeta?.path ?? "/chat/:channelUrl()",
    meta: _91channelUrl_93coTr4lNSlpMeta || {},
    alias: _91channelUrl_93coTr4lNSlpMeta?.alias || [],
    redirect: _91channelUrl_93coTr4lNSlpMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/chat/[channelUrl].vue").then(m => m.default || m)
  },
  {
    name: forgetPwdsaScYPmHAfMeta?.name ?? "forgetPwd",
    path: forgetPwdsaScYPmHAfMeta?.path ?? "/forgetPwd",
    meta: forgetPwdsaScYPmHAfMeta || {},
    alias: forgetPwdsaScYPmHAfMeta?.alias || [],
    redirect: forgetPwdsaScYPmHAfMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/forgetPwd.vue").then(m => m.default || m)
  },
  {
    name: indexb4NojfA6IZMeta?.name ?? "index",
    path: indexb4NojfA6IZMeta?.path ?? "/",
    children: [
  {
    name: businessCardtwAQB0JH2vMeta?.name ?? "index-businessCard",
    path: businessCardtwAQB0JH2vMeta?.path ?? "businessCard",
    meta: businessCardtwAQB0JH2vMeta || {},
    alias: businessCardtwAQB0JH2vMeta?.alias || [],
    redirect: businessCardtwAQB0JH2vMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/businessCard.vue").then(m => m.default || m)
  },
  {
    name: conversation20NDyP907PMeta?.name ?? "index-conversation",
    path: conversation20NDyP907PMeta?.path ?? "conversation",
    meta: conversation20NDyP907PMeta || {},
    alias: conversation20NDyP907PMeta?.alias || [],
    redirect: conversation20NDyP907PMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/conversation.vue").then(m => m.default || m)
  },
  {
    name: feedbackA0rzLnPkl9Meta?.name ?? "index-feedback",
    path: feedbackA0rzLnPkl9Meta?.path ?? "feedback",
    meta: feedbackA0rzLnPkl9Meta || {},
    alias: feedbackA0rzLnPkl9Meta?.alias || [],
    redirect: feedbackA0rzLnPkl9Meta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/feedback.vue").then(m => m.default || m)
  },
  {
    name: historyfQ5V8SchtJMeta?.name ?? "index-history",
    path: historyfQ5V8SchtJMeta?.path ?? "history",
    meta: historyfQ5V8SchtJMeta || {},
    alias: historyfQ5V8SchtJMeta?.alias || [],
    redirect: historyfQ5V8SchtJMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/history.vue").then(m => m.default || m)
  },
  {
    name: homeqZnUCvdC5xMeta?.name ?? "index-home",
    path: homeqZnUCvdC5xMeta?.path ?? "home",
    meta: homeqZnUCvdC5xMeta || {},
    alias: homeqZnUCvdC5xMeta?.alias || [],
    redirect: homeqZnUCvdC5xMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/home.vue").then(m => m.default || m)
  },
  {
    name: profileST8hSeqJZmMeta?.name ?? "index-profile",
    path: profileST8hSeqJZmMeta?.path ?? "profile",
    meta: profileST8hSeqJZmMeta || {},
    alias: profileST8hSeqJZmMeta?.alias || [],
    redirect: profileST8hSeqJZmMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/profile.vue").then(m => m.default || m)
  },
  {
    name: setupAbPi67W5zYMeta?.name ?? "index-setup",
    path: setupAbPi67W5zYMeta?.path ?? "setup",
    children: [
  {
    name: assignYt7b8boVuvMeta?.name ?? "index-setup-assign",
    path: assignYt7b8boVuvMeta?.path ?? "assign",
    meta: assignYt7b8boVuvMeta || {},
    alias: assignYt7b8boVuvMeta?.alias || [],
    redirect: assignYt7b8boVuvMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/assign.vue").then(m => m.default || m)
  },
  {
    name: basic1ogyZJlcUoMeta?.name ?? "index-setup-basic",
    path: basic1ogyZJlcUoMeta?.path ?? "basic",
    meta: basic1ogyZJlcUoMeta || {},
    alias: basic1ogyZJlcUoMeta?.alias || [],
    redirect: basic1ogyZJlcUoMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/basic.vue").then(m => m.default || m)
  },
  {
    name: blacklistOUMTkl7sUfMeta?.name ?? "index-setup-blacklist",
    path: blacklistOUMTkl7sUfMeta?.path ?? "blacklist",
    meta: blacklistOUMTkl7sUfMeta || {},
    alias: blacklistOUMTkl7sUfMeta?.alias || [],
    redirect: blacklistOUMTkl7sUfMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/blacklist.vue").then(m => m.default || m)
  },
  {
    name: conversationTagPVum5eyrswMeta?.name ?? "index-setup-conversationTag",
    path: conversationTagPVum5eyrswMeta?.path ?? "conversationTag",
    meta: conversationTagPVum5eyrswMeta || {},
    alias: conversationTagPVum5eyrswMeta?.alias || [],
    redirect: conversationTagPVum5eyrswMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/conversationTag.vue").then(m => m.default || m)
  },
  {
    name: queueY5sT0wYH0JMeta?.name ?? "index-setup-queue",
    path: queueY5sT0wYH0JMeta?.path ?? "queue",
    meta: queueY5sT0wYH0JMeta || {},
    alias: queueY5sT0wYH0JMeta?.alias || [],
    redirect: queueY5sT0wYH0JMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/queue.vue").then(m => m.default || m)
  },
  {
    name: quickReply88Pos4JvBaMeta?.name ?? "index-setup-quickReply",
    path: quickReply88Pos4JvBaMeta?.path ?? "quickReply",
    meta: quickReply88Pos4JvBaMeta || {},
    alias: quickReply88Pos4JvBaMeta?.alias || [],
    redirect: quickReply88Pos4JvBaMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/quickReply.vue").then(m => m.default || m)
  },
  {
    name: rolesyudp4mAw9JMeta?.name ?? "index-setup-roles",
    path: rolesyudp4mAw9JMeta?.path ?? "roles",
    meta: rolesyudp4mAw9JMeta || {},
    alias: rolesyudp4mAw9JMeta?.alias || [],
    redirect: rolesyudp4mAw9JMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/roles.vue").then(m => m.default || m)
  },
  {
    name: satisfactionVcex2dFec7Meta?.name ?? "index-setup-satisfaction",
    path: satisfactionVcex2dFec7Meta?.path ?? "satisfaction",
    meta: satisfactionVcex2dFec7Meta || {},
    alias: satisfactionVcex2dFec7Meta?.alias || [],
    redirect: satisfactionVcex2dFec7Meta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/satisfaction.vue").then(m => m.default || m)
  },
  {
    name: sensitivemFyzmiUjNHMeta?.name ?? "index-setup-sensitive",
    path: sensitivemFyzmiUjNHMeta?.path ?? "sensitive",
    meta: sensitivemFyzmiUjNHMeta || {},
    alias: sensitivemFyzmiUjNHMeta?.alias || [],
    redirect: sensitivemFyzmiUjNHMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/sensitive.vue").then(m => m.default || m)
  },
  {
    name: teamyllA8WfsJHMeta?.name ?? "index-setup-team",
    path: teamyllA8WfsJHMeta?.path ?? "team",
    meta: teamyllA8WfsJHMeta || {},
    alias: teamyllA8WfsJHMeta?.alias || [],
    redirect: teamyllA8WfsJHMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/team.vue").then(m => m.default || m)
  },
  {
    name: webAccesstaeklfDA3AMeta?.name ?? "index-setup-webAccess",
    path: webAccesstaeklfDA3AMeta?.path ?? "webAccess",
    children: [
  {
    name: setupxqaZf401VPMeta?.name ?? "index-setup-webAccess-setup",
    path: setupxqaZf401VPMeta?.path ?? "setup",
    meta: setupxqaZf401VPMeta || {},
    alias: setupxqaZf401VPMeta?.alias || [],
    redirect: setupxqaZf401VPMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/webAccess/setup.vue").then(m => m.default || m)
  },
  {
    name: webZiBPBDgzgSMeta?.name ?? "index-setup-webAccess-web",
    path: webZiBPBDgzgSMeta?.path ?? "web",
    meta: webZiBPBDgzgSMeta || {},
    alias: webZiBPBDgzgSMeta?.alias || [],
    redirect: webZiBPBDgzgSMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/webAccess/web.vue").then(m => m.default || m)
  }
],
    meta: webAccesstaeklfDA3AMeta || {},
    alias: webAccesstaeklfDA3AMeta?.alias || [],
    redirect: webAccesstaeklfDA3AMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup/webAccess.vue").then(m => m.default || m)
  }
],
    meta: setupAbPi67W5zYMeta || {},
    alias: setupAbPi67W5zYMeta?.alias || [],
    redirect: setupAbPi67W5zYMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index/setup.vue").then(m => m.default || m)
  }
],
    meta: indexb4NojfA6IZMeta || {},
    alias: indexb4NojfA6IZMeta?.alias || [],
    redirect: indexb4NojfA6IZMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inviteUserVerifyitJ4Iw1CqCMeta?.name ?? "inviteUserVerify",
    path: inviteUserVerifyitJ4Iw1CqCMeta?.path ?? "/inviteUserVerify",
    meta: inviteUserVerifyitJ4Iw1CqCMeta || {},
    alias: inviteUserVerifyitJ4Iw1CqCMeta?.alias || [],
    redirect: inviteUserVerifyitJ4Iw1CqCMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/inviteUserVerify.vue").then(m => m.default || m)
  },
  {
    name: loginNylpizp0EMMeta?.name ?? "login",
    path: loginNylpizp0EMMeta?.path ?? "/login",
    meta: loginNylpizp0EMMeta || {},
    alias: loginNylpizp0EMMeta?.alias || [],
    redirect: loginNylpizp0EMMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registerJwHMV599erMeta?.name ?? "register",
    path: registerJwHMV599erMeta?.path ?? "/register",
    meta: registerJwHMV599erMeta || {},
    alias: registerJwHMV599erMeta?.alias || [],
    redirect: registerJwHMV599erMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerVerifyBC5CVh3WTHMeta?.name ?? "registerVerify",
    path: registerVerifyBC5CVh3WTHMeta?.path ?? "/registerVerify",
    meta: registerVerifyBC5CVh3WTHMeta || {},
    alias: registerVerifyBC5CVh3WTHMeta?.alias || [],
    redirect: registerVerifyBC5CVh3WTHMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/registerVerify.vue").then(m => m.default || m)
  },
  {
    name: resetPwd0agTdlHzHQMeta?.name ?? "resetPwd",
    path: resetPwd0agTdlHzHQMeta?.path ?? "/resetPwd",
    meta: resetPwd0agTdlHzHQMeta || {},
    alias: resetPwd0agTdlHzHQMeta?.alias || [],
    redirect: resetPwd0agTdlHzHQMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/resetPwd.vue").then(m => m.default || m)
  },
  {
    name: googleMail9cekhKBqA2Meta?.name ?? "test-googleMail",
    path: googleMail9cekhKBqA2Meta?.path ?? "/test/googleMail",
    meta: googleMail9cekhKBqA2Meta || {},
    alias: googleMail9cekhKBqA2Meta?.alias || [],
    redirect: googleMail9cekhKBqA2Meta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/test/googleMail.vue").then(m => m.default || m)
  },
  {
    name: verifyFxp1VgTEdqMeta?.name ?? "verify",
    path: verifyFxp1VgTEdqMeta?.path ?? "/verify",
    meta: verifyFxp1VgTEdqMeta || {},
    alias: verifyFxp1VgTEdqMeta?.alias || [],
    redirect: verifyFxp1VgTEdqMeta?.redirect || undefined,
    component: () => import("C:/Users/user/Desktop/anders/anders/projects/vue/aican/pages/verify.vue").then(m => m.default || m)
  }
]